body {
  padding: 0;
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #f43554; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#background {
  position: fixed;
  background-image: radial-gradient(100% 100%, #ff6a00 20%, #ee0979 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  animation: fadeIn 2s ease-in-out forwards;
  animation-delay: 1s; }

.admin-container {
  z-index: 2;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 480px) {
    .admin-container {
      width: 100%;
      align-items: center;
      justify-content: center; } }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .admin-container {
      width: 720px; } }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .admin-container {
      width: 920px; } }
  @media screen and (min-width: 1025px) {
    .admin-container {
      width: 1024px; } }

form {
  text-align: center;
  padding: 40px 80px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: solid 1px rgba(255, 255, 255, 0.15);
  width: intrinsic;
  display: flex;
  flex-direction: column;
  align-items: center; }
  form h1 {
    margin-top: 0px; }
  form .input-group,
  form .form-field {
    padding: 10px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between; }

.error-message {
  color: white; }

#login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 140px); }

#new-track-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 140px); }

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.page {
  position: relative; }

.withSidePadding {
  padding: 0px 10px; }
