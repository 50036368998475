table {
  border: solid 1px rgba(255, 255, 255, 0.2);
  padding: 0px;
  border-radius: 5px;
  width: 100%; }
  table thead tr th {
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.9);
    text-align: left; }
    table thead tr th:first-of-type {
      border-top-left-radius: 5px; }
    table thead tr th:last-of-type {
      border-top-right-radius: 5px; }
  table tbody tr td {
    padding: 10px 20px;
    color: rgba(255, 255, 255, 0.8); }
    table tbody tr td a {
      color: rgba(255, 255, 255, 0.9);
      text-decoration: none; }
  table tbody tr:hover {
    background: rgba(255, 255, 255, 0.05); }
