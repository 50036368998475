#error-message {
  display: flex;
  background: white;
  color: #f43554;
  padding: 15px;
  border-radius: 5px;
  margin-top: 30px;
  width: 80%;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: space-between; }
  #error-message .close {
    cursor: pointer;
    color: white;
    background: #f43554;
    padding: 2px 8px 4px;
    border-radius: 20px;
    justify-content: center;
    align-content: center;
    display: flex; }
    #error-message .close:hover {
      background: #ff6a00; }
