#tracks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 220px;
  grid-gap: 30px 10px; }
  #tracks .track {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 22px);
    height: 100%;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    padding: 0px;
    border: solid 1px rgba(255, 255, 255, 0.2);
    color: white;
    text-decoration: none; }
    #tracks .track video,
    #tracks .track img {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      display: flex;
      width: 100%; }
    #tracks .track .title {
      display: flex;
      padding: 15px 0px 0px;
      align-items: flex-start;
      justify-content: center; }
