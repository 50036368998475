#notification-message {
  display: flex;
  color: white;
  background: #69b65c;
  padding: 16px;
  border-radius: 8px;
  margin-top: 30px;
  width: calc(100% - 32px);
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: space-between; }
  #notification-message .close {
    cursor: pointer;
    background: white;
    color: #69b65c;
    width: 24px;
    height: 24px;
    font-size: 14px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    #notification-message .close:hover {
      color: #69b65c; }
