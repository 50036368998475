.vertical-separator {
  width: 1px;
  margin: 0 50%;
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  display: flex;
  justify-content: center;
  align-self: center; }

#edit-playlist-forms {
  display: grid;
  grid-template-columns: 49% 2% 49%;
  align-items: center;
  align-content: center;
  justify-content: center; }

#delete-playlist-details-form .form-group {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-content: flex-start; }
  #delete-playlist-details-form .form-group b,
  #delete-playlist-details-form .form-group p {
    text-align: left; }
  #delete-playlist-details-form .form-group p,
  #delete-playlist-details-form .form-group label {
    font-size: 10pt; }

#playlist-tracks-section {
  flex-direction: column; }

#search-form-component {
  width: 100%;
  padding-bottom: 8px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
  display: grid;
  grid-template-columns: 10% 80% 10%;
  align-items: center;
  justify-content: center;
  justify-items: center; }
  #search-form-component form {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 450px; }

.section {
  margin: 24px 0px;
  width: calc(100% - 32px);
  padding: 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: solid 1px rgba(255, 255, 255, 0.15);
  display: flex; }
  .section form {
    padding: 0px;
    width: 100%;
    background: none;
    border: none; }
  .section .section-heading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between; }

#search-tracks {
  width: 100%;
  display: flex;
  flex-direction: column; }
  #search-tracks table {
    width: 100%; }
    #search-tracks table tbody tr:hover td {
      background: rgba(255, 255, 255, 0.075); }
    #search-tracks table tbody tr td {
      padding: 4px 0px; }
      #search-tracks table tbody tr td:last-of-type {
        text-align: right; }
