.page #nav-bar {
  background: rgba(255, 255, 255, 0.1);
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  color: white;
  height: 48px;
  margin: 0px;
  padding: 0px; }
  .page #nav-bar .container .withSidePadding #logo a {
    color: white;
    text-decoration: none; }
  .page #nav-bar .container .withSidePadding ul#desktop-menu li a {
    color: white; }
  .page #nav-bar .container .withSidePadding #hamburger svg #iPhone-8 {
    stroke: #fff; }

.page #mobile-menu {
  position: fixed;
  top: 0px;
  background-image: radial-gradient(100% 100%, #ff6a00 20%, #ee0979 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }
  .page #mobile-menu #mobile-menu-header {
    border-bottom: solid 1px rgba(255, 255, 255, 0.3); }
    .page #mobile-menu #mobile-menu-header #close-icon svg path {
      stroke: #fff; }
  .page #mobile-menu ul li {
    border-bottom-color: rgba(255, 255, 255, 0.3); }
    .page #mobile-menu ul li a {
      color: white; }
